import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import NavBar from "./Navbar";
import EmailIcon from "@mui/icons-material/Email";
import { notification, theme } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { logIn } from "../Store/store";
import LoginIcon from "@mui/icons-material/Login";
import { tokens, useMode } from "../theme";
import "./form.css";
import { useTranslation } from "react-i18next";

const defaultTheme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const { loading, accessToken, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [theme] = useMode();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  console.log("auth state =>", loading, accessToken, error);

  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
    Usertype: "",
    User_Id: "0",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const { Email, Password } = formData;

    if (Email === "") {
      notification.error({
        message: "Please enter Email",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "270px",
        },
      });
      return;
    }
    if (Password === "") {
      notification.error({
        message: "Please enter Password",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "270px",
        },
      });
      return;
    }

    dispatch(logIn({ email: Email.trim(), password: Password.trim() }));
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (accessToken) {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="cotainer" style={{ marginLeft: "-180px" }}>
      <NavBar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: 5,
              // marginTop:20,
              borderRadius: 3,
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              margin: "auto",
              left: 0,
              right: 0,
              maxWidth: "600px",
            }}
          >
            <Avatar
              sx={{ m: 0, backgroundColor: "#402E7A" }}
              style={{ marginTop: "0px", marginBottom: "10px" }}
            >
              <LoginIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              className="form_title"
              sx={{ textTransform: "capitalize" }}
            >
              {t("login")}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                }}
              >
                <EmailIcon
                  sx={{ position: "absolute", right: "20px", color: "#afb8cd" }}
                ></EmailIcon>
                <TextField
                  //  id="input-with-sx"
                  margin="normal"
                  required
                  fullWidth
                  label={t("email")}
                  name="Email"
                  onChange={handleInputChange}
                  autoFocus
                  value={formData.Email}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                }}
              >
                <LockOutlinedIcon
                  sx={{ position: "absolute", right: "20px", color: "#afb8cd" }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="Password"
                  label={t("password")}
                  type="password"
                  value={formData.Password}
                  onChange={handleInputChange}
                />
              </Box>
              {/* 
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="Email"
                onChange={handleInputChange}
                autoFocus
                value={formData.Email}
              /> */}

              {/* <TextField
                margin="normal"
                required
                fullWidth
                name="Password"
                label="password"
                type="password"
                value={formData.Password}
                onChange={handleInputChange}
              /> */}
              <Button
                type="submit"
                fullWidth
                // color="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5c7cfc",
                  borderRadius: 100,
                  padding: "10px",
                  maxWidth: 300,
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                className="cstm-btn"

                // Disable the button when loading is true
              >
                {loading ? "Signing In..." : t("login")}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
