import { Box, useTheme, Button, Typography  } from "@mui/material";
import {tokens} from "../theme";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../Store/store";
import UserMenu from "./UserInfoDropdown";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';



const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const {loading, userName} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();


  

  const handleLogout = () => {
    
    // // Remove cookies
    // Cookies.remove('email');
    // Cookies.remove('role');
    // Cookies.remove('username');

    // // Navigate to the root path
    // navigate('/login');
    dispatch(logOut());
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2} sx={{
      backgroundColor: colors.white[500],  borderRadius: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
// Replace 'blue' with the desired color
    }}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems = "center"
        backgroundColor='transparent'
        sx={{borderEndStartRadius:3}}
       
        
      >

        {/* <h1>
          <Trans
            i18nKey="welcome_user"
            values={{ userName }}
            components={{ 1: <span style={{ color: 'blue', fontWeight: 'bold' }} /> }}
          />
        </h1>
         */}
          <Typography variant="body1" sx={{ mr: 2, color: '#5472fc', fontSize:20 }}>
         {t('welcome')}
        &nbsp; <span style={{ textTransform: "Capitalize", fontWeight: 600, color: colors.cstmPurple[500] }}>{t('welcome_user', { userName })}</span>
          {/* {t('welcome')} <span style={{textTransform:"Capitalize", fontWeight:600,  color:colors.cstmPurple[500]}}>{userName}</span> */}
        </Typography>
          

      </Box>


          



      {/* ICONS */}
      <Box display="flex">
          
        <UserMenu></UserMenu>


    
     
      </Box>
    </Box>
  );
};

export default Topbar;
