
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../Store/store";
import { tokens } from "../theme";
import { useNavigate } from 'react-router-dom';
import {  useTheme, Button, } from "@mui/material";
import "../components/cstm.css"
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useTranslation } from 'react-i18next';



export default function UserMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { loading, userName } = useSelector(state => state.auth);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleLogout = () => {

        // // Remove cookies
        // Cookies.remove('email');
        // Cookies.remove('role');
        // Cookies.remove('username');

        // // Navigate to the root path
        // navigate('/login');
        dispatch(logOut());
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}

            >
                <PersonIcon sx={{marginRight:2}} />{userName}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                style={{textAlign:"center"}}
                className='headerDropdowen'
              


                

            >
              
             
                  <span className='username'>
                        {userName}
                  </span>
              
                <Button
                    onClick={handleLogout}
                    sx={{
                        backgroundColor: colors.cstmPurple[100],
                        color: colors.cstmPurple[500],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 0px",
                        textTransform:"capitalize",
                        textAlign:"left",
                        display:"flex",
                        alignItems:"center",
                        boxShadow:"",
                        gap:1,
                        "&:hover": {
                            color: colors.cstmPurple[500], // White color on hover
                            backgroundColor: colors.cstmPurple[100],
                        },
                    }}
                >
                    {t('logout')}
                    <PowerSettingsNewIcon></PowerSettingsNewIcon>
                </Button>
              
            </Menu>

           
        </div>
    );
}
