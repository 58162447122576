import { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../global/Header";
import axios from 'axios';
import {  useNavigate  } from "react-router-dom";
import { notification } from "antd";
import { useLocation } from "react-router-dom";

const UserDetails = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { clientname } = location.state || {};;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [dataGridKey, setDataGridKey] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCellClick = (params) => {
    const clickTime = new Date().getTime();
    const doubleClickThreshold = 300;

    if (clickTime - lastClickTime < doubleClickThreshold) {
      handleCellDoubleClick(params.row.clientname);
    }

    setLastClickTime(clickTime);
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        // https://stt.transcribe4all.com/
        // https://primadeta-map-1546434413742.ew.r.appspot.com/
        "https://stt.transcribe4all.com/list-user-files",
        { username: clientname },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const formattedData = response.data.map((fileName, index) => ({
        id: index + 1,
        fileName,
      }));
      setData(formattedData);
      setDataLoaded(true);
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!dataLoaded) {
      fetchData();
    }
  }, [dataLoaded]);

  const handleCellDoubleClick = (clientname) => {
    navigate(`/employeedetails`, { state: { clientname } });
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);

  };

  const handleDeleteClick = async () => {
    try {
        setLoading(true);
        if (selectedRows.length > 0) {
            //const selectedFiles = selectedRows.map(row => row.fileName); // Extract file names from selected rows

            const payload = {
                username: clientname,
                files: selectedRows // Filter out undefined or null values
            };

            const response = await axios.post("https://stt.transcribe4all.com/delete-all-files", payload);

            if (response && response.status === 200) {
                notification.success({
                    message: "Success!",
                    description: "Data Deleted Successfully",
                    style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "40px",
                        width: "220px",
                    },
                });
                
                // Refresh data after delete
                fetchData();
            } else {
                notification.error({
                    message: "Fout!",
                    description: "Er is iets fout gegaan",
                    style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "40px",
                        width: "220px",
                    },
                });
            }
        }
    } catch (error) {
        notification.error({
            message: "Fout!",
            description: "Er is iets fout gegaan",
            style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "40px",
                width: "220px",
            },
        });
    } finally {
        setLoading(false);
    }
};


  const columns = [
  { field: "id", headerName: "ID", width: 40 },
  { field: "fileName", headerName: "File Name", flex: 1 },
];

  return (
    <Box m="20px">
      <Header title="Bucket Details" subtitle="Managing the Bucket" />
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteClick}
        disabled={selectedRows.length === 0 || loading}
        sx={{ mt: 2 }}
      >
        {loading ? "Deleting Selected..." : "Delete Selected"}
       
      </Button>
      
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "12px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row": {
            fontSize: "14px", // Adjust the font size for rows
          },
        }}
      >
        <DataGrid
            key={dataGridKey}
            checkboxSelection
            rows={data}
            columns={columns}
            onCellClick={handleCellClick}
            onSelectionModelChange={handleSelectionModelChange}
            getRowId={(row) => row.fileName} // Use the fileName property as the row identifier
        />
      </Box>
    </Box>
  );
};

export default UserDetails;
