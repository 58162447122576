import React, { useEffect, useState } from "react";
import { Box, useTheme, Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../global/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { backendApiUrl } from "../utils/constants";
import qs from "qs";
import "../components/cstm.css";
import { useTranslation, Trans } from "react-i18next";

const ListBuckets = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const ClientName = Cookies.get("clientname");
  // const navigate = useNavigate();
  const {
    accessToken,
    email,
    accountType: role,
  } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [dataGridKey, setDataGridKey] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { t, i18n } = useTranslation();

  const [selectedFileNames, setSelectedFileNames] = useState([]);

  const handleCellClick = (params) => {
    const clickTime = new Date().getTime();
    const doubleClickThreshold = 300;

    if (clickTime - lastClickTime < doubleClickThreshold) {
      handleCellDoubleClick(params.row.employeename);
    }

    setLastClickTime(clickTime);
  };

  useEffect(() => {
    if (!dataLoaded) {
      fetchData();
    }
  }, [dataLoaded]);

  const handleCellDoubleClick = (employeeName) => {
    // if (Role !== "SuperAdmin" && Role !== "Admin" && Role !== "Employee") {
    // navigate(`/reports`, { state: { employeeName: employeeName, clientName: ClientName } });
    // }
  };

  const handleSelectionModelChange = (selectionModel) => {
    if (selectionModel.length > 0) {
      const selectedRowsData = selectionModel.map((selectedId) => {
        const foundRow = data.find((row) => row.id === selectedId);
        return foundRow.fileName; // This will be the entire row object with all columns
      });
      setSelectedFileNames(selectedRowsData);
    }
    setSelectedRows(selectionModel);
  };

  const handleDownloadClick = async () => {
    try {
      setLoadingDownload(true);

      if (selectedRows.length === 1) {
        const selectedRowsData = selectedRows.map((selectedId) => {
          const foundRow = data.find((row) => row.id === selectedId);
          return foundRow; // This will be the entire row object with all columns
        });

        const selectedFileName = selectedRowsData[0].fileName;

        const formData = new FormData();
        formData.append("token", accessToken);
        formData.append("email", selectedRowsData[0].email);
        formData.append("filename", selectedFileName);
        const response = await axios.post(
          `${backendApiUrl}/bucket/download_file`,
          formData
        );
        // Trigger file download
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = selectedFileName;
        link.click();
        // } else {
        //   // Selected file is not a .txt file, show error message
        //   alert("Please select a .txt file for download.");
        // }
      } else {
        // More than one file is selected, show error message
        alert("Please select only one file for download.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleDeleteClick = async () => {
    try {
      setLoadingDelete(true);
      if (selectedRows.length > 0) {
        const selectedRowsData1 = data.filter((row) =>
          selectedRows.includes(row.id)
        );
        const dataToSend = selectedRowsData1.map(({ fileName }) => ({
          fileName: fileName,
        }));
        // const dataToSend1 = selectedRowsData1.map(({ employeename }) => ({ employeename, clientname: ClientName }));
        const formData = new FormData();
        formData.append("token", accessToken);
        formData.append("email", selectedRowsData1[0].email);
        formData.append("filename", selectedRowsData1[0].fileName);

        const response = await axios.post(
          `${backendApiUrl}/bucket/delete_file`,
          formData
        );
        if (response && response.status === 200) {
          notification.success({
            message: "Success!",
            description: "Data Deleted Successfully",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });

          // Refresh data after delete
          setSelectedRows([]);
          setDataGridKey((prevKey) => prevKey + 1);
          fetchData();
        } else {
          notification.error({
            message: "Fout!",
            description: "Er is iets fout gegaan",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        }
        // setSelectedRows([]);
      }
      //setLoading(false);
    } catch (error) {
      notification.error({
        message: "Fout!",
        description: "Er is iets fout gegaan",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
      //setLoading(false);
    } finally {
      setLoadingDelete(false); // Set loading to false after the request is complete (success or failure)
    }
  };
  // https://primadeta-map-1546434413742.ew.r.appspot.com/list-all-files http://127.0.0.1:5000/list-all-files
  const fetchData = async () => {
    try {
      const url = `${backendApiUrl}/bucket/list_files`;
      let bodyFormData = new FormData();
      bodyFormData.append("token", accessToken);
      const res = await axios.post(url, bodyFormData);
      console.log("bucket list res =>", res);
      if (res.data.status === "success") {
        const data = res.data.data;
        let formattedData = [];
        for (const obj of data) {
          const arr = obj.filenames.map((name, idx) => ({
            id: formattedData.length + idx + 1,
            username: obj.username,
            email: obj.email,
            fileName: name,
          }));
          formattedData = formattedData.concat(arr);
        }
        setData(formattedData);
        setDataLoaded(true);
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      notification.error({
        message: "Fout!",
        description: error.message || error.toString(),
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
    }
  };

  const columns = [
    { field: "id", headerName: t("table_id"), width: 80 },
    {
      field: "fileName", // Change field name to "fileName"
      headerName: t("table_filename"),
      flex: 1,
    },
    {
      field: "username", // Change field name to "fileName"
      headerName: t("table_owner"),
      width: 150,
    },
  ];

  return (
    <Box m="20px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 0,
        }}
      >
        <Header
          title={t("table_head")}
          subtitle={t("table_subtitle")}
          sx={{ marginBottom: 0 }}
        />
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteClick}
            disabled={selectedRows.length === 0 || loadingDelete} // Update disabled attribute
            sx={{
              mt: 2,
              p: 1,
              px: 2,
              borderRadius: 100,
              color: "#fff",
              fontWeight: 600,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            {loadingDelete ? (
              <CircularProgress size={24} color="inherit" /> // Show spinner while loading
            ) : (
              t("delete_btn")
            )}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadClick}
            disabled={selectedRows.length === 0 || loadingDownload} // Update disabled attribute
            sx={{
              mt: 2,
              p: 1,
              px: 2,
              borderRadius: 100,
              marginLeft: 3,
              backgroundColor: "#5472fc",
              color: "#fff",
              fontWeight: 600,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              "&:hover": {
                backgroundColor: colors.cstmPurple[500],
              },
            }}
          >
            {loadingDownload ? (
              <CircularProgress size={24} color="inherit" /> // Show spinner while loading
            ) : (
              t("download_btn")
            )}
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "#fff !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cstmPurple[500],
            borderBottom: "none",
            fontSize: "12px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.cstmPurple[500],
          },
          "& .MuiCheckbox-root": {
            color: `"#fff",!important`,
          },
          "& .MuiDataGrid-row": {
            fontSize: "14px", // Adjust the font size for rows
          },
        }}
      >
        <DataGrid
          key={dataGridKey}
          checkboxSelection
          rows={data}
          columns={columns}
          onCellClick={handleCellClick}
          onSelectionModelChange={handleSelectionModelChange}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
};

export default ListBuckets;
