import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
//import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { backendApiUrl } from "../utils/constants";
import { AccountType } from "../Store/auth";
import { useSelector } from "react-redux";

const defaultTheme = createTheme();

export default function UpdateAdminInfo() {
  //const role = Cookies.get("role");
  const location = useLocation();
  const { rowData } = location.state;
  // const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    // user_id: rowData.user_id,
    email: rowData.email || "",
    username: rowData.username || "",
    organization: rowData.organ_name || "",
    role: rowData.role || "",
  });

  useEffect(() => {
    // Update formData when rowData changes
    setFormData({
      // user_id: rowData.user_id,
      email: rowData.email || "",
      username: rowData.username || "",
      organization: rowData.organ_name || "",
      role: rowData.role || "",
    });
  }, [rowData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isChangedFormData = () => {
    const { email, username, organization, role } = formData;
    return (
      email.trim() !== rowData.email ||
      username.trim() !== rowData.username ||
      organization.trim() !== rowData.organ_name ||
      role.trim() !== rowData.role
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, username, organization, role } = formData;

    if (
      email.trim() === "" ||
      username.trim() === "" ||
      organization.trim() === "" ||
      role.trim() === ""
    ) {
      // Handle validation errors
      return;
    }

    const bodyData = new FormData();
    bodyData.append("token", accessToken);
    bodyData.append("email", email.trim());
    bodyData.append("username", username.trim());
    bodyData.append("organization", organization.trim());
    bodyData.append("new_role", role.trim());

    try {
      setLoading(true);
      const response = await axios.post(
        `${backendApiUrl}/auth/update_account`,
        bodyData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("update user res =>", response);
      if (response && (response.status === 200 || response.status === 204)) {
        notification.success({
          message: "Information Updated!",
          description: "User has been updated successfully", // Replace UserName with the actual user name
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px",
            width: "220px",
          },
        });

        // navigate("/adminsdetails", { replace: true });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        window.alert(
          "Er is iets fout gegaan. Please check if Email and Username is correct."
        );
      } else {
        console.error("Error occurred during update:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}

        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update User
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              onChange={handleInputChange}
              autoFocus
              value={formData.email}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label="Username"
              type="text"
              value={formData.username}
              onChange={handleInputChange}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="organization"
              label="Organization"
              type="text"
              value={formData.organization}
              onChange={handleInputChange}
              // InputProps={{
              //   readOnly: true}}
            />

            <FormControl margin="normal" required fullWidth>
              <InputLabel>User Role</InputLabel>
              <Select
                label="User Role"
                name="role"
                value={formData.role || ""}
                onChange={handleInputChange}
              >
                <MenuItem key="Admin" value={AccountType.Admin}>
                  Admin
                </MenuItem>
                <MenuItem key="Client" value={AccountType.Client}>
                  Client
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading || !isChangedFormData()}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
