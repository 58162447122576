// store.js
import { configureStore, createSlice } from "@reduxjs/toolkit";
import { authSlice } from "./auth";

const userSlice = createSlice({
  name: "user",
  initialState: {
    email: "",
    usertype: "",
  },
  clearUserData: (state) => {
    state.email = "";
    state.usertype = "";
  },
  reducers: {
    setUserData: (state, action) => {
      state.email = action.payload.email;
      state.usertype = action.payload.usertype;
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;
export const { logIn, register, logOut, confirmAccount, updatePassword } =
  authSlice.actions;

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    auth: authSlice.reducer,
  },
});

export default store;
