
import * as React from 'react';
import { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { notification } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../Store/store';
import { useTranslation, Trans } from 'react-i18next';

const defaultTheme = createTheme();

const UpdatePassword = () => {
  const { accessToken, email, loading, error, message } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  const [formData, setFormData] = useState({
    password: "",
  });

  useEffect(() => {
    if (!loading ) {
      if( !error) {
        if( message ) {
          notification.success({
            message,
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "320px",
            },
          });
        }
      }
      else {
        notification.error({
          message: error,
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px",
            width: "290px",
          },
        });
      }
    }
  }, [loading, message, error]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { password } = formData;

    if (password === "") {
      notification.error({
        message: "Please enter new password",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "270px",
        },
      });
      return;
    }

    dispatch(updatePassword({email, password, token: accessToken}));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              padding:5,
              // marginTop:20,
              borderRadius:3,
              position:"absolute",
              top:"50%",
              transform:"translateY(-50%)",
              margin:"auto",
              left:0,
              right:0,
              maxWidth:"600px"
            
          }}
          className="formcls"
        >
          <Avatar   sx={{ m: 0, backgroundColor:"#402E7A" }}
              style={{ marginTop: "0px", marginBottom:"10px" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('upload_user')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate  sx={{ mt: 1 , width:"100%"}}>
         

                 <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", position:"relative" }}>
               <LockOutlinedIcon sx={{position:"absolute", right:"20px", color:"#afb8cd"}} />
             <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label= {t('password')}
              type="password"
              value={formData.password}
              onChange={handleInputChange}
            

            />
          </Box>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor:"#5c7cfc"  ,borderRadius:100, padding:"10px", maxWidth:300, marginLeft:"auto" , marginRight:"auto", display:"block"}}
                onClick={handleSubmit}
                disabled={loading} // Disable the button when loading is true
              >
                {loading ? "Updating Password..." : t("update_password")}
              </Button>

          </Box>
        </Box>
      
      </Container>
    </ThemeProvider>
  );
}
export default UpdatePassword;