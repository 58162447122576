import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import axios from "axios";
import TextField from "@mui/material/TextField";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAudioService } from "../Hook/AudioHook";
import SummarizeTab from "../components/text/summarize";
import ContentGenerationTab from "../components/text/content_generation";
// import https from 'https';
import CustomTabPanel, { a11yProps } from "../components/tabs/CustomTabPanel";

import Grid from '@mui/material/Grid';
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import "../components/cstm.css"
import { useTranslation, Trans } from 'react-i18next';




const LoadingOverlay = ({ loading, children }) => (
  <div style={{ position: "relative", minHeight: "100vh" }}>
    {loading && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <CircularProgress size={60} />
      </div>
    )}
    {children}
  </div>
);



const UploadText = () => {

    const {
    audioFile,
    setAudioFile
  } = useAudioService();
  
  useEffect(()=>{
    if(audioFile){
      setAudioFile("")
    }
  },[])
  
  const {
    selectedSrcLanguage,
    setSelectedSrcLanguage,
    textFile = [],
    textFiles = [],
    textModel,
    setTextModel,
    textSummarizationResult,
    setTextSummarizationResult,
    handleTextSelect,
    handleTextSummarize,
    isTextSummarizable,
    processing,
    elapsedTime,
  } = useAudioService();
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <LoadingOverlay loading={processing}>
      <Grid container sx={{ paddingTop: "30px" }}>
        <Grid item xs={6} sx={{ paddingRight: 2 }}>
          <Box
            sx={{
              width: "100%",
              height: "85vh",
              overflowY: "auto",
              paddingBottom: 8,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              borderRadius: 3,
              padding: 3
            }}
          >
            <Card
              variant="outlined"
              sx={{ width: "100%", marginBottom: "10px", position: "relative", textAlign: "center", border: 0, boxShadow: "none" }}

            >
              <CardContent sx={{ border: "1px dashed #d0d0d0", borderRadius: 3 }}>
                <Typography variant="h2" sx={{ fontSize: 18, fontWeight: 600, color: colors.cstmBlue[500] }}> {t('upload_text')}</Typography>
                <br />
                <input
                  type="file"
                  onChange={handleTextSelect}
                  multiple
                  style={{ display: "none" }}
                  id={`text_input`}
                />
                <label htmlFor="text_input">
                  <Button component="span" variant="contained" sx={{ backgroundColor: colors.cstmPurple[500], borderRadius: "100px" }}>
                    {t('file_text')}
                  </Button>
                </label>
                <br /> <br />
                <Typography variant="h5" color="black">
                  {textFile ? (
                    <>
                      Geselecteerd bestand:{' '}
                      {textFile.map((file, index) => (
                        <span key={index}>
                          {index > 0 && ", "}
                          {file.name}
                        </span>
                      ))}
                    </>
                  ) : (
                    t('file_text')
                  )}
                </Typography>
              </CardContent>
            </Card>


            <FormControl
              variant="outlined"
              sx={{
                minWidth: 230,
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {t('select_langtext')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedSrcLanguage}
                onChange={(e) => setSelectedSrcLanguage(e.target.value)}
                label="Select Language"
              >
                <MenuItem value={"Dutch"} onClick={() => changeLanguage('nl')}>Dutch</MenuItem>
                <MenuItem value={"English"} onClick={() => changeLanguage('en')}>English</MenuItem>
              </Select>
            </FormControl>




          </Box>

        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: 2 }}>
          <Box
            sx={{
              width: "100%",
              height: "85vh",
              overflowY: "auto",
              paddingBottom: 8,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              borderRadius: 3,
              padding: 3
            }}
          >


            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Audio Tabs"
                indicatorColor="primary"
                className="cstmTab"
                sx={{ background: "#fff", borderRadius: "10px", padding: "10px", border: "1px solid #402E7A" }}

              >
                <Tab label={t('summry_tab_label')} {...a11yProps(0)} />
                <Tab label={t('contentGen_tab_label')} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
              <SummarizeTab />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
              <ContentGenerationTab />
            </CustomTabPanel>

          </Box>

        </Grid>
      </Grid>


    </LoadingOverlay>
  );
};

export default UploadText;
