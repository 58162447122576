// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';



// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    nl: {
      translation: translationNL,
    },
     en: {
      translation: translationEN,
    },
  },
  lng: 'nl', // Set the default language to Dutch
  fallbackLng: 'nl', // Set fallback language to Dutch
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
