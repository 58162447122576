import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { backendApiUrl } from "../utils/constants";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AudioContext = React.createContext();

export const useAudioService = () => {
  const context = React.useContext(AudioContext);
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`);
  }
  return context;
};

export default function AudioServiceProvider() {
  const { accessToken, expiration, userName } = useSelector(
    (state) => state.auth
  );
  const { t, i18n } = useTranslation();
  const [audioFile, setAudioFile] = useState();
  const [requestId, setRequestId] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("Dutch"); // Default to Dutch

  // Multiple File Select
  // const [textFiles, setTextFiles] = useState([]);

  //** Transcription */
  const [speakers, setSpeakers] = useState("");
  const [transcriptionText, setTranscriptionText] = useState();

  // Summarize
  const [audioModel, setAudioModel] = useState("AssemblyAI"); // OpenAI, ClaudeAI
  const [audioSummarizationResult, setAudioSummarizationResult] = useState();

  // Content Generation
  const [audioContentResult, setAudioContentResult] = useState();
  const [audioContentPrompt, setAudioContentPrompt] = useState();
  const [isFileSelect, setIsFileSelect] = useState(false);

  // Text Summarize
  const [textRequestId, setTextRequestId] = useState();
  const [selectedSrcLanguage, setSelectedSrcLanguage] = useState("Dutch"); // Default to Dutch
  const [textFile, setTextFile] = useState([]);
  const [llmRequestId, setLlmRequestId] = useState();
  const [textModel, setTextModel] = useState("AssemblyAI"); // OpenAI, ClaudeAI
  const [textSummarizationResult, setTextSummarizationResult] = useState();

  // Text Content Generation
  const [textContentPrompt, setTextContentPrompt] = useState();
  const [textContentResult, setTextContentResult] = useState();

  const [processing, setProcessing] = useState(false);
  const [elapsedTime, setElapsedTime] = useState();
  const [message, setMessage] = useState("");
  const [error, setError] = useState();
  const [transcribebtn, setTranscribeBtn] = useState(false);

  // Total count of all usage minutes
  const [usagesData, setUsagesData] = useState({
    data: [],
    totalUsage: 0,
  });

  const handleSpeakersChange = (event) => {
    const value = event.target.value;
    if (value === "" || (/^\d*$/.test(value) && value >= 1 && value <= 10)) {
      // Allow empty string or valid numeric input between 1 and 10
      setSpeakers(value);
    }
  };

  const upload_file = async () => {
    try {
      setProcessing(true);
      const formData = new FormData();
      formData.append("token", accessToken);
      formData.append("file", audioFile);
      const url = `${backendApiUrl}/audio/upload_file`;
      const res = await axios.post(url, formData);

      if (res?.data?.status === "success") {
        const { message } = res.data;
        setAudioFile(message);
        setIsFileSelect(false);
        setTranscribeBtn(true);
        if (message) {
          notification.success({
            message: "Success!",
            description: "Audio Upload successfully.",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        } else {
          throw new Error(res.data.message);
        }
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      console.log("Transcription error =>", errorMessage);
      setError(errorMessage);
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hour(s) and ${remainingMinutes} minute(s)`;
  };

  const handleGetUsagesByUsername = async () => {
    try {
      const formData = new FormData();
      formData.append("username", userName);
      formData.append("token", accessToken);

      const url = `${backendApiUrl}/auth/get_usage_by_username`;
      const res = await axios.post(url, formData);

      if (res?.data?.status === "success") {
        const totalUsage = res.data.data.reduce(
          (sum, item) => sum + item.usage,
          0
        );

        setUsagesData({
          data: res.data.data,
          totalUsage: convertMinutesToHours(totalUsage),
        });
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    }
  };
  const handleAudioSelect = (event) => {
    const files = event.target.files;
    const supportedExtensions = [
      ".mp4",
      ".mp3",
      ".wav",
      ".ogg",
      ".aac",
      ".m4a",
    ]; // Add more if needed
    const selectedAudioFiles = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return supportedExtensions.includes("." + extension);
    });

    if (selectedAudioFiles.length === 0) {
      alert(t("select_audio_err"));
      return; // Prevent updating state if no audio files were selected
    }

    setAudioFile(selectedAudioFiles[0]);
    setIsFileSelect(true);
    setRequestId(null);
  };

  const handleTranscription = async () => {
    setTranscriptionText("");
    if ((!audioFile && !requestId) || !speakers) {
      notification.warning({
        message: "Warning!",
        description: "Please choose an audio file and select speakers",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
      return;
    }
    try {
      setProcessing(true);
      const startTime = new Date().getTime();
      const formData = new FormData();
      formData.append("token", accessToken);
      if (requestId) {
        formData.append("request_id", requestId);
      } else {
        formData.append("filepath", audioFile);
      }
      formData.append("language", selectedLanguage);
      formData.append("speakers", speakers);
      formData.append("p2redaction", false);

      const url = `${backendApiUrl}/audio/transcribe_file`;
      const res = await axios.post(url, formData);
      const endTime = new Date().getTime();
      const timeTakenInSeconds = (endTime - startTime) / 1000;
      setElapsedTime(timeTakenInSeconds.toFixed(3));
      if (res.status === 200) {
        const { data, message } = res.data;
        if (data) {
          setTranscriptionText(data);
          setMessage(message);
          console.log("Transacription result =>", res.data);
          notification.success({
            message: "Success!",
            description: "Audio transcribed successfully.",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        } else {
          throw new Error(res.data.message);
        }
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      console.log("Transcription error =>", errorMessage);
      setError(errorMessage);
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleAudioSummarize = async () => {
    setAudioSummarizationResult("");
    if ((!audioFile && !requestId) || !audioModel) {
      notification.warning({
        message: "Warning!",
        description: "Please choose an audio file and select model",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });

      return;
    }

    try {
      setProcessing(true);
      const startTime = new Date().getTime();
      const formData = new FormData();
      formData.append("token", accessToken);
      if (requestId) {
        formData.append("request_id", requestId);
      } else {
        formData.append("filepath", audioFile);
      }
      formData.append("language", selectedLanguage);

      // formData.append("model", audioModel);
      formData.append("speakers", speakers);

      console.log("summarization params", {
        requestId,
        audioFile,
        selectedLanguage,
        audioModel,
      });

      const url = `${backendApiUrl}/audio/summarize`;
      const res = await axios.post(url, formData);
      const endTime = new Date().getTime();
      const timeTakenInSeconds = (endTime - startTime) / 1000;
      setElapsedTime(timeTakenInSeconds.toFixed(3));
      if (res?.data?.status === "success") {
        const { data, message } = res.data;
        if (data) {
          setAudioSummarizationResult(data);
          setMessage(message);
          await handleGetUsagesByUsername();

          console.log("Summarization result =>", res.data);

          notification.success({
            message: "Success!",
            description: "Audio summarized successfully.",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        } else {
          throw new Error(res.data.message);
        }
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      console.log("error", error);
      const errorMessage = error.message || error.toString();
      console.log("Summarization error =>", errorMessage);
      setError(errorMessage);
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleAudioContentGeneration = async () => {
    setAudioContentResult("");
    if ((!audioFile && !requestId) || !audioModel || !audioContentPrompt) {
      notification.warning({
        message: "Warning!",
        description: "Please choose an audio file and select model",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });

      return;
    }

    try {
      setProcessing(true);
      const startTime = new Date().getTime();
      const formData = new FormData();
      formData.append("token", accessToken);
      if (requestId) {
        formData.append("request_id", requestId);
      } else {
        formData.append("filepath", audioFile);
      }
      formData.append("language", selectedLanguage);
      // formData.append("model", audioModel);
      formData.append("prompt", audioContentPrompt);

      console.log("Content generation params", {
        requestId,
        audioFile,
        selectedLanguage,
        audioModel,
        audioContentPrompt,
      });

      const url = `${backendApiUrl}/audio/content_generate`;
      const res = await axios.post(url, formData);
      const endTime = new Date().getTime();
      const timeTakenInSeconds = (endTime - startTime) / 1000;
      setElapsedTime(timeTakenInSeconds.toFixed(3));

      if (res?.data?.status === "success") {
        const { data, message } = res.data;
        if (data) {
          setAudioContentResult(data);
          setMessage(message);
          await handleGetUsagesByUsername();

          console.log("Content generation result =>", res.data);

          notification.success({
            message: "Success!",
            description: "Content generated successfully.",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        } else {
          throw new Error(res.data.message);
        }
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      console.log("Content generation error =>", errorMessage);
      setError(errorMessage);
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  // LLM
  const handleTextSelect = (event) => {
    const files = event.target.files;
    const supportedExtensions = [".txt", ".doc", ".docx", ".pdf"];

    const selectedFile = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return supportedExtensions.includes("." + extension);
    });

    if (!selectedFile) {
      alert(t("select_text_err"));
      return;
    }

    setTextFile(selectedFile);
    setLlmRequestId(null);
  };

  const handleTextSummarize = async () => {
    setTextSummarizationResult("");
    if ((!textFile && !textRequestId) || !textModel) {
      notification.warning({
        message: "Warning!",
        description: "Please choose a text file and select speakers",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
      return;
    }
    try {
      setProcessing(true);
      const startTime = new Date().getTime();

      const formData = new FormData();

      if (textRequestId) {
        formData.append("request_id", textRequestId);
      } else {
        if (Array.isArray(textFile)) {
          textFile?.forEach((file) => {
            if (file instanceof File || file instanceof Blob) {
              formData.append("file", file);
            } else if (typeof file === "string") {
              const blob = new Blob([file], { type: "text/plain" });
              formData.append("file", blob, "text_file.txt");
            } else {
              throw new Error("Invalid file type");
            }
          });
        } else {
          throw new Error("Invalid file type");
        }
      }

      formData.append("token", accessToken);
      formData.append("language", selectedSrcLanguage);
      formData.append("model", textModel);

      console.log("summarization params", {
        textFile,
        selectedSrcLanguage,
        textModel,
      });

      const url = `${backendApiUrl}/documents/summarize`;
      const res = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const endTime = new Date().getTime();
      const timeTakenInSeconds = (endTime - startTime) / 1000;
      setElapsedTime(timeTakenInSeconds.toFixed(3));
      console.log("text summarization result =>", res);

      if (res.data?.status === "success") {
        const { data, message } = res.data;
        if (data) {
          setTextSummarizationResult(data);
          setMessage(message);
          console.log("Text Summarization result =>", res.data);
          notification.success({
            message: "Success!",
            description: "Text summarized successfully.",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        } else {
          throw new Error(res.data.message);
        }
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      console.log("Text Summarization error =>", errorMessage);
      setError(errorMessage);
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleTextContentGeneration = async () => {
    setTextContentResult("");
    if ((!textFile && !textRequestId) || !textModel || !textContentPrompt) {
      notification.warning({
        message: "Warning!",
        description: "Please choose an text file and select model",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });

      return;
    }

    try {
      setProcessing(true);
      const startTime = new Date().getTime();
      const formData = new FormData();
      formData.append("token", accessToken);
      if (textRequestId) {
        formData.append("request_id", textRequestId);
      } else {
        if (Array.isArray(textFile)) {
          for (let i = 0; i < textFile.length; i++) {
            formData.append("file", textFile[i]);
          }
        } else {
          formData.append("file", textFile);
        }
      }
      formData.append("language", selectedSrcLanguage);
      formData.append("model", textModel);
      formData.append("prompt", textContentPrompt);

      console.log("Content generation params", {
        textRequestId,
        textFile,
        selectedSrcLanguage,
        textModel,
        textContentPrompt,
      });

      const url = `${backendApiUrl}/documents/content_generate`;
      const res = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const endTime = new Date().getTime();
      const timeTakenInSeconds = (endTime - startTime) / 1000;
      setElapsedTime(timeTakenInSeconds.toFixed(3));

      if (res.data?.status === "success") {
        const { data, message, request_id } = res.data;
        // if (data && request_id) {
        if (data) {
          setTextContentResult(data);
          setTextRequestId(request_id);
          setMessage(message);

          console.log("Content generation result =>", res.data);

          notification.success({
            message: "Success!",
            description: "Content generated successfully.",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        } else {
          throw new Error(res.data.message);
        }
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      console.log("Content generation error =>", errorMessage);
      setError(errorMessage);
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const values = {
    setTranscribeBtn,
    transcribebtn,
    audioFile,
    setAudioFile,
    upload_file,
    handleAudioSelect,
    requestId,
    selectedLanguage,
    setSelectedLanguage,
    speakers,
    handleSpeakersChange,
    isTransribable: (!!audioFile || !!requestId) && !!speakers && !processing,
    transcriptionText,
    setTranscriptionText,
    handleTranscription,
    usagesData,
    setUsagesData,
    handleGetUsagesByUsername,

    // Audio summarize
    audioModel,
    setAudioModel,
    audioSummarizationResult,
    setAudioSummarizationResult,
    handleAudioSummarize,
    isAudioSummarizable:
      (!!audioFile || !!requestId) && !!audioModel && !processing,

    // Content
    audioContentResult,
    setAudioContentResult,
    audioContentPrompt,
    setAudioContentPrompt,
    handleAudioContentGeneration,
    isAudioContentGeneratable:
      (!!audioFile || !!requestId) &&
      !!audioModel &&
      !!audioContentPrompt &&
      !processing,

    // Text Summarize
    selectedSrcLanguage,
    setSelectedSrcLanguage,
    textFile,
    textModel,
    setTextModel,
    textSummarizationResult,
    setTextSummarizationResult,
    handleTextSelect,
    handleTextSummarize,
    isTextSummarizable:
      (!!textFile || !!llmRequestId) && !!textModel && !processing,

    // Text Content Generation
    textContentPrompt,
    setTextContentPrompt,
    textContentResult,
    setTextContentResult,
    handleTextContentGeneration,
    isTextContentGeneratable:
      (!!textFile || !!textRequestId) &&
      !!textModel &&
      !!textContentPrompt &&
      !processing,

    processing,
    elapsedTime,
    message,
    error,
    isFileSelect,
  };

  return (
    <AudioContext.Provider value={values}>{<Outlet />}</AudioContext.Provider>
  );
}
