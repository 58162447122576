import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { notification } from "antd";
import NavBar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../Store/store";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import  './form.css'
import { useTranslation } from 'react-i18next';

const defaultTheme = createTheme();

export default function Register() {
  const dispatch = useDispatch();
  const { loading, error, message, accessToken } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    organization: "",
    account_type: "Client", // Admin
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (accessToken) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!loading ) {
      if( !error ) {
        if(message) {
          console.log("register message =>", message);
          notification.success({
            message: message,
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "290px",
            },
          });
   
        }
      }
      else {
        notification.error({
          message: error,
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px",
            width: "290px",
          },
        });
      }
    }
  }, [loading, message, error]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAccountType = (type) => {
    setFormData((prevState) => ({
      ...prevState,
      account_type: type,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { username, email, password, organization, account_type } = formData;

    if (
      !username.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      organization.trim() === "" ||
      account_type.trim() === ""
    ) {
      notification.error({
        message: "Fout!",
        description: "Vul alle velden in!",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
      return;
    }
    dispatch(
      register({
        email: email.trim(),
        password: password.trim(),
        username: username.trim(),
        organization: organization.trim(),
        account_type: account_type.trim(),
      })
    );
  };

  return (
    <div style={{ marginLeft: "-180px" }}>
      <NavBar />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              padding:5,
              // marginTop:20,
              borderRadius:3,
              position:"absolute",
              top:"50%",
              transform:"translateY(-50%)",
              margin:"auto",
              left:0,
              right:0,
              maxWidth:"600px"
            }}
          >
           <Avatar
              sx={{ m: 0, backgroundColor:"#402E7A" }}
              style={{ marginTop: "0px", marginBottom:"10px" }}
            >
              <PersonIcon />
            </Avatar>

            <Typography component="h1" variant="h5" className="form_title">
                               {t('register')}

            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 , width:"100%"}}

            >

              
                <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", position:"relative" }}>
            <PersonIcon sx={{position:"absolute", right:"20px", color:"#afb8cd"}}></PersonIcon>
                     <TextField
                margin="normal"
                required
                fullWidth
                label={t('username')}
                name="username"
                onChange={handleInputChange}
                autoFocus
                value={formData.username}
              />
          </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", position:"relative" }}>
            <EmailIcon sx={{position:"absolute", right:"20px", color:"#afb8cd"}}></EmailIcon >
                   
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('email')}
                name="email"
                onChange={handleInputChange}
                value={formData.email}
              />
          </Box>
                 <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", position:"relative" }}>
            <LockOutlinedIcon sx={{position:"absolute", right:"20px", color:"#afb8cd"}}></LockOutlinedIcon >
                   
                <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('password')}
                type="password"
                value={formData.password}
                onChange={handleInputChange}
              />
          </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width:"100%", position:"relative" }}>
            <CorporateFareIcon sx={{position:"absolute", right:"20px", color:"#afb8cd"}}></CorporateFareIcon>
                   
             <TextField
                margin="normal"
                required
                fullWidth
                name="organization"
                 label={t('organisatien')}
                type="organization"
                value={formData.organization}
                onChange={handleInputChange}
              />
          </Box>

     
           
           
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 230,
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                 {t('select_type')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={formData.account_type}
                  onChange={(e) => handleAccountType(e.target.value)}
                  label="Select Language"
                >
                  <MenuItem value={"Client"}>{t('client')}</MenuItem>
                  <MenuItem value={"Admin"}>{t('admin')}</MenuItem>
                </Select>
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{  mt: 3, mb: 2, backgroundColor: "#5c7cfc"  ,borderRadius:100, padding:"10px", maxWidth:300, marginLeft:"auto" , marginRight:"auto", display:"block"}}
                onClick={handleSubmit}
                disabled={loading} // Disable the button when loading is true
              >
                {loading ? "Registering User..." : t('register_user')}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
