import Cookies from "js-cookie";
import _ from 'lodash';

export const setCookies = (state) => {
  const jsonStr = JSON.stringify(state);
  Cookies.set("auth", jsonStr);
};

export const getCookies = (defaultState) => {
  const jsonStr = Cookies.get("auth");
  let state = _.cloneDeep(defaultState);
  if (jsonStr) {
    try {
      state = JSON.parse(jsonStr);
    } catch (err) {
      state = _.cloneDeep(defaultState);
    }
  }
  return state;
};
