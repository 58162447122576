import React, { useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Topbar from "../global/Topbar";
import Sidebar from "../global/sidebar";
import { useSelector, useDispatch } from "react-redux";


// import UpdatePassword from "./UpdatePassword";
// import UploadAudio from "./UploadAudio";
// import ListBuckets from "./ListAllBuckets";
// import ListRegisteredUsers from "./ListUsers";
// import UserDetails from "./ListBucketFilesByUser";
// import Clients from "./Clients";
// import Employees from "./Employees";
// import Admins from "./Admins";
// import UpdateAdminInfo from "./UpdateAdminInfo";
import { logOut } from "../Store/store";
import AudioServiceProvider from "../Hook/AudioHook";

function Dashboard() {
  const [isSidebar, setIsSidebar] = useState(true);
  const { accessToken } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!accessToken) {
    dispatch(logOut);
    navigate("/login");
  }

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content" style={{margin:12 , marginRight:50}}>
        <Topbar setIsSidebar={setIsSidebar} />
        <AudioServiceProvider>
          <div className="content1">
            <Outlet />
          </div>
        </AudioServiceProvider>
      </main>
    </div>
  );
}

export default Dashboard;
