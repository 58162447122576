import axios from "axios";
import { createAppSlice } from "./appSlice";
import { setCookies, getCookies } from "../utils/cookie";
import { backendApiUrl } from "../utils/constants";

export const AccountType = {
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Client: "Client"
}

const defaultState = {
  accessToken: "",
  email: "",
  expiration: "",
  accountType: "", // Admin || Client
  userName: "",
  organization: "",
  activeStatus: -1, // -1, 0, 1
  message: "",
  loading: false,
  error: null,
};

export const authSlice = createAppSlice({
  name: "auth",
  initialState: {
    ...getCookies(defaultState),
    message: "",
    loading: false,
    error: null,
  },

  reducers: (create) => ({
    logIn: create.asyncThunk(
      async ({ email, password }) => {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        try {
          const res = await axios.post(
            `${backendApiUrl}/auth/login`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          console.log("login result =>", res.data);
          if (res.data.status === "success") {
            if (res.data.access_token) {
              return res.data;
            } else {
              throw new Error(res.data.message);
            }
          } else {
            throw new Error(res.data.message);
          }
        } catch (error) {
          console.log("login api error =>", error);
          if (error.response?.data?.message)
            throw new Error(error.response.data.message);
          else throw error;
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
          if (action.error) {
            state.error = action.payload?.message || action.error.message;
            state.accessToken = "";
            state.email = "";
            state.userName = "";
            state.activeStatus = -1;
            state.message = action.payload?.message || action.error.message;
          }
          setCookies(state);
        },
        fulfilled: (state, action) => {
          const {
            access_token,
            email,
            expiration,
            account_type,
            username,
            organization,
            active_status,
            message,
          } = action.payload;
          state.accessToken = access_token;
          state.email = email;
          state.expiration = new Date(expiration);
          state.accountType = account_type;
          state.userName = username;
          state.organization = organization;
          state.activeStatus = active_status;
          state.message = message;
          state.error = null;
          state.loading = false;

          setCookies(state);
        },
      }
    ),
    register: create.asyncThunk(
      async ({ email, password, username, organization, account_type }) => {
        try {
          const formData = new FormData();
          formData.append("email", email);
          formData.append("password", password);
          formData.append("username", username);
          formData.append("organization", organization);
          formData.append("account_type", account_type);
          
          const res = await axios.post(
            `${backendApiUrl}/auth/register_user`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          if (res.data.status === "success") {
            console.log("registration success result =>", res.data);
            return res.data;
          } else {
            throw new Error(res.data.message);
          }
        } catch (error) {
          if (error.response?.data?.message)
            throw new Error(error.response.data.message);
          else throw error;
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
          state.error = action.error.message || action.error.code;
          state.message = "";
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const { message } = action.payload;
          state.message = message;
          state.error = null;
        },
      }
    ),
    confirmAccount: create.asyncThunk(
      async({ token }) => {
        const url = `${backendApiUrl}/auth/confirmaccount?token=${token}`;
        try {
          const res = await axios.get(url);
          console.log("confirmation result =>", res.data);
          if (res.data.status === "success") {

            if (res.data.access_token) {
              return res.data;
            } else {
              throw new Error(res.data.message);
            }
          } else {
            throw new Error(res.data.message);
          }
        } catch (error) {
          console.log("confirmAccount  error =>", error);
          if (error.response?.data?.message)
            throw new Error(error.response.data.message);
          else throw error;
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
          if(!state.accessToken) {
            if (action.error) {
              state.error = action.payload?.message || action.error.message;
              state.accessToken = "";
              state.email = "";
              state.userName = "";
              state.isAdmin = false;
              state.message = action.payload?.message || action.error.message;
            }
            setCookies(state);
          }

        },
        fulfilled: (state, action) => {
          const {
            access_token,
            email,
            expiration,
            account_type,
            username,
            organization,
            active_status,
            message,
          } = action.payload;
          state.accessToken = access_token;
          state.email = email;
          state.expiration = new Date(expiration);
          state.accountType = account_type;
          state.userName = username;
          state.organization = organization;
          state.activeStatus = active_status;
          state.message = message;
          state.error = null;
          state.loading = false;

          setCookies(state);
        },
      }
    ),
    logOut: (state, action) => {
      state.loading = false;
      state.error = "";
      state.accessToken = "";
      state.email = "";
      state.userName = "";
      state.accountType = "";
      state.message = "";

      setCookies(state);
    },
    updatePassword: create.asyncThunk(async ({ email, password, token }) => {
      try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        formData.append("token", token);
        const res = await axios.post(
          `${backendApiUrl}/auth/update_password`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (res.data.status === "success") {
          console.log("registration success result =>", res.data);
          return res.data;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        if (error.response?.data?.message)
          throw new Error(error.response.data.message);
        else throw error;
      }
    },
    {
      pending: (state) => {
        state.loading = true;
      },
      rejected: (state, action) => {
        state.loading = false;
        if (action.error) {
          state.error = action.payload?.message || action.error.message;
        }
        setCookies(state);
      },
      fulfilled: (state, action) => {
        console.log("updatePassword result =>", action.payload);
        const {
          access_token,
          email,
          expiration,
          username,
          account_type,
          message,
        } = action.payload;
        state.accessToken = access_token;
        state.email = email;
        state.expiration = new Date(expiration);
        state.userName = username;
        state.message = message;
        state.accountType = account_type;
        state.error = null;
        state.loading = false;

        setCookies(state);
      },
    }
  ),
  }),
});
