import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavBar from "./Navbar";
import { notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { confirmAccount } from "../Store/store";

const defaultTheme = createTheme();

export default function ConfirmAccountPage() {
  const dispatch = useDispatch();
  const { loading, accessToken, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [firstLoaded, setFirstLoaded] = useState(false);

  const sendToken = (token) => {
    if (loading) return;
    dispatch(confirmAccount({ token }));
  };

  useEffect(() => {
    if (searchParams) {
      const token = searchParams.get("token");
      console.log("ConfirmAccount token =>", token);
      if (token && !accessToken && !firstLoaded) {
        sendToken(token);
        setFirstLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (accessToken) {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <div style={{ marginLeft: "-180px" }}>
      <NavBar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, bgcolor: "secondary.main" }}
              style={{ marginTop: "60px" }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Confirming your account...
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
