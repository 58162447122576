import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import "./RouterHome.css";

import Login from "./authentication/Login";
import Register from "./authentication/Signup";
import Dashboard from "./MainPage";

import UpdatePassword from "./MainPage/UpdatePassword";
import UploadAudio from "./MainPage/UploadAudio";
import ListBuckets from "./MainPage/ListAllBuckets";
import ListRegisteredUsers from "./MainPage/ListUsers";
import UserDetails from "./MainPage/ListBucketFilesByUser";
import Clients from "./MainPage/Clients";
// import Employees from "./MainPage/Employees";
import Admins from "./MainPage/Admins";
import UpdateAdminInfo from "./MainPage/UpdateAdminInfo";
import ConfirmAccountPage from "./authentication/ConfirmAccount";
import UploadText from "./MainPage/UploadText";

function RouterHome() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/auth/confirmaccount" element={<ConfirmAccountPage />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="clientsdetails" element={<Clients />} />
            {/* <Route path="employeedetails" element={<Employees />} /> */}
            <Route path="adminsdetails" element={<Admins />} />
            <Route path="updateaccount" element={<UpdateAdminInfo />} />
            <Route path="updatepassword" element={<UpdatePassword />} />
            <Route path="home" element={<UploadAudio />} />
            <Route path="allbuckets" element={<ListBuckets />} />
            <Route path="registeredusers" element={<ListRegisteredUsers />} />
            <Route path="userdetails" element={<UserDetails />} />
            <Route path="text" element={<UploadText />} />
            <Route path="" element={<Navigate to="home" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default RouterHome;
