import { useEffect, useState } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../global/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { backendApiUrl } from "../utils/constants";
import { useSelector } from "react-redux";
import { AccountType } from "../Store/auth";

const Clients = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { accountType, organization, email, accessToken } = useSelector(
    (state) => state.auth
  );

  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [dataGridKey, setDataGridKey] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsUserName, setSelectedRowsUserName] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCellClick = (params) => {
    const clickTime = new Date().getTime();
    const doubleClickThreshold = 300;
    if (clickTime - lastClickTime < doubleClickThreshold) {
      handleCellDoubleClick(params);
    }

    setLastClickTime(clickTime);
  };

  const fetchData = async () => {
    try {
      // const url =
      //   accountType === AccountType.SuperAdmin
      //     ? `${backendApiUrl}/auth/list_all_users?token=${accessToken}`
      //     : `${backendApiUrl}/auth/list_all_users_in_organization?token=${accessToken}&organization=${organization}`;
      const url = `${backendApiUrl}/auth/list_all_users`
      let bodyFormData = new FormData();
      bodyFormData.append('token', accessToken); 
      const response = await axios.post(url, bodyFormData);
      console.log("clients ret =>", response);
      if (response.data.status === "success") {
        const data = response.data.data;
        console.log("clients data =>", data);
        // const formattedData = data.map((item, index) => ({
        //   ...item,
        //   id: index + 1,
        // }));
        const formattedData = [];
        let index = 1;
        for(const obj of data) {
          if( obj.email !== email ) {
            formattedData.push({
              ...obj,
              id: index
            });
            index += 1;
          }
        }
        setData(formattedData);
      }
      setDataLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      notification.error({
        message: "Fout!",
        description: error.message || error.toString(),
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
    }
  };

  useEffect(() => {
    if (!dataLoaded) {
      fetchData();
    }
  }, [dataLoaded]);

  const handleCellDoubleClick = (params) => {
    if( accountType === AccountType.SuperAdmin ) {
      navigate(`/dashboard/updateaccount`, { state: { rowData: params.row } });
    }    
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      // Perform the delete operation using the selected row IDs
      if (selectedRows.length > 0) {
        const selectedObjects = [];
        for (const id of selectedRows) {
          const obj = data.find((it) => it.id === id);
          if (obj) {
            selectedObjects.push(obj.email);
          }
        }
        const emails = selectedObjects.join(",");
        const bodyData = new FormData();
        bodyData.append("email", emails);
        bodyData.append("token", accessToken);

        const response = await axios.post(
          `${backendApiUrl}/auth/delete_account`,
          bodyData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response && response.status === 200) {
          notification.success({
            message: "Success!",
            description: "Deleted Successfully",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });

          // Refresh data after delete
          fetchData();
        } else {
          notification.error({
            message: "Fout!",
            description: "Er is iets fout gegaan",
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              width: "220px",
            },
          });
        }
      }
      //setLoading(false);
    } catch (error) {
      notification.error({
        message: "Fout!",
        description: "Er is iets fout gegaan",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
      //setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const activateUser = async (email) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", accessToken);
      formData.append("email", email);

      const res = await axios.post(
        `${backendApiUrl}/auth/activate_account`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (res.data.status === "success") {
        fetchData();
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      notification.error({
        message: "Fout!",
        description: error.message || error.toString(),
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "220px",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 1,
    },
    {
      field: "organ_name",
      headerName: "Organization",
      type: "string",
      flex: 1,
    },
    {
      field: "ative",
      headerName: "Status",
      type: "string",
      flex: 1,
      renderCell: ({ row: { email, role, active } }) => {
        return active ? (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === AccountType.Admin
                ? colors.greenAccent[600]
                : role === AccountType.Client
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              Activated
            </Typography>
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              activateUser(email);
            }}
            disabled={loading}
            sx={{ m: "0 auto" }}
          >
            {loading ? "Activating..." : "Activate"}
          </Button>
        );
      },
    },

    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === AccountType.Admin
                ? colors.greenAccent[600]
                : role === AccountType.Client
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Clients Details" subtitle="Managing the Client Members" />
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteClick}
        disabled={selectedRows.length === 0 || loading}
        sx={{ mt: 2 }}
      >
        {loading ? "Deleting Selected..." : "Delete Selected"}
      </Button>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "12px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row": {
            fontSize: "14px", // Adjust the font size for rows
          },
        }}
      >
        <DataGrid
          key={dataGridKey}
          checkboxSelection
          rows={data}
          columns={columns}
          onCellClick={handleCellClick}
          onSelectionModelChange={handleSelectionModelChange}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
};

export default Clients;
