import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useSelector } from "react-redux";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import TitleIcon from '@mui/icons-material/Title';
import SettingsIcon from '@mui/icons-material/Settings';
import { AccountType } from "../Store/auth";
import '../components/cstm.css'
import { useTranslation, Trans } from 'react-i18next';
import ArticleIcon from '@mui/icons-material/Article';



const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "#FFFFFF",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accountType } = useSelector((state) => state.auth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { t } = useTranslation();
  


  // const shouldRenderItem = accountType === "SuperAdmin" || accountType === "Admin";
  const isSuperAdmin = accountType === AccountType.SuperAdmin;
  const isAdmin = accountType === AccountType.Admin;
  const isClient = accountType === AccountType.Client;
  // const isEmployee = accountType === "Employee";

  return (
    <Box
      sx={{
        height: "100vh",
        borderRadius:3,
        margin:1.5,

        "& .pro-sidebar-inner": {
          // backgroundColor: "lightgray",
          backgroundColor: colors.white[500],
          borderRadius:3
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#5472fc !important",
        },
        "& .pro-menu-item.active": {
          color: "#000 !important",
          fontWeight:600
        },
        // backgroundColor: colors.cstmPurple[500],
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // backgroundColor:"red"
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        className="cstmSidebar"
      
        
        style={{ backgroundColor: colors.white[500], borderRadius: "40px", margin: 5, borderRadius: 3, }}
      >

     



        <Menu
          iconShape="square"
          className="nav-bar"
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
          className="baricon"
         
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={
              isCollapsed ? (
                <MenuOutlinedIcon sx={{ color: "#FFFFFF", padding:0 }} />
              
              ) : undefined
            }
            style={{
              margin: "10px auto 20px auto",
              color: colors.blueAccent[700],
              padding:0
            
            }}
          >

            
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
             
              >
                {/* <img
                  alt="profile-user"
                  width="50px"
                  height="50px"
                  src={logo}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                /> */}
                <Typography variant="h3" fontSize={20}  color="#5472fc" fontWeight={600}>
                  {t('app_name')}
                  </Typography>
                <IconButton
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  sx={{
                    "& svg": {
                      fontSize: "30px",
                      color: "#402E7A", // Set the default color to white
                    },
                    "&:hover": {
                      "& svg": {
                        color: "#5472fc", // Maintain white color on hover
                      },
                    },
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Typography
              variant="h4"
              color="#402E7A"
              sx={{ m: "15px 0 5px 20px",  fontWeight:600 , fontSize:18}}
              className="nav-title"
            >
              {t('add_title')}    
             
            </Typography>

            <Item
              title={t('audio')}
              to="/dashboard/home"
              icon={<Link to="/dashboard/home">< AudiotrackIcon sx={{ color: colors.cstmBlue[500] }} /></Link>}
              selected={selected}
              setSelected={setSelected}

            
            />
            <Item
              title={t('document')}
              to="/dashboard/text"
              icon={<Link to="/dashboard/text"><ArticleIcon sx={{ color: colors.cstmBlue[500] }} /></Link> }
              selected={selected}
              setSelected={setSelected}
            />

            <>
              <Typography
                variant="h4"
                color="#402E7A"
                sx={{ m: "15px 0 5px 20px", fontWeight: 600 , fontSize:18}}
                className="nav-title"
              >
                {t('view_title')}    
              </Typography>
              <Item
                title={t('all_bucket')}
                to="/dashboard/allbuckets"
                icon={
                  <Link to="/dashboard/allbuckets"><MapOutlinedIcon sx={{ color: colors.cstmBlue[500] }} /></Link> }
                selected={selected}
                setSelected={setSelected}
              />

              {(isSuperAdmin || isAdmin) && (
                <>
                  {/* <Item
                  title="View Users"
                  to="/dashboard/registeredusers"
                  icon={<MapOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}

                  <Item
                    title={t('view_clients')}
                    to="/dashboard/clientsdetails"
                    icon={
                      <Link to="/dashboard/clientsdetails"><MapOutlinedIcon sx={{ color: colors.cstmBlue[500] }} /></Link>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </>
              )}
              {/* {isClient  && 
                  <Item
                    title="Employee Accounts"
                    to="/employeedetails"
                    icon={<PersonOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                } */}

              {accountType === "SuperAdmin" && (
                <Item
                  title={t('view_admins')}
                  to="/dashboard/adminsdetails"
                  icon={
                    <Link to="/dashboard/adminsdetails"><MapOutlinedIcon sx={{ color: colors.cstmBlue[500] }} /></Link>}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </>

            <Typography
              variant="h4"
              color="#402E7A"
              sx={{ m: "15px 0 5px 20px", fontWeight: 600, fontSize:18 }}
              className="nav-title"
            >
              {t('setting_title')} 
            </Typography>
            <Item
              title={t('update_password')}              
              to="/dashboard/updatepassword"
              icon={
                <Link to="/dashboard/updatepassword"> <SettingsIcon sx={{ color: colors.cstmBlue[500] }} /></Link>
             }
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
