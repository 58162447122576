import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";

const PAGES = [];

const HeaderMUI = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{ background: "#5C7CFC" }}
        style={{ width: "100%" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Button
            variant="link"
            color="default"
            sx={{ fontSize: "18px" }}
            startIcon={<HomeIcon sx={{ color: "white" }} />}
          >
            {t("app_name")}
          </Button>
          <Box display="flex" gap={2}>
            <Button
              onClick={() => navigate("/login")}
              sx={{
                // backgroundColor: colors.blueAccent[700],
                backgroundColor: "#402E7A",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: 50,

                "&:hover": {
                  color: "#FFFFFF", // White color on hover
                  backgroundColor: colors.blueAccent[700],
                },
              }}
            >
              {t("login")}
              <LoginIcon sx={{ marginLeft: 1 }}></LoginIcon>
            </Button>
            <Button
              onClick={() => navigate("/register")}
              // variant="outlined"
              sx={{
                // backgroundColor: colors.blueAccent[700],
                backgroundColor: "#402E7A",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: 50,
                "&:hover": {
                  color: "#FFFFFF",
                  backgroundColor: colors.blueAccent[700],
                  // White color on hover
                },
              }}
            >
              {t("register")}
              <PersonIcon sx={{ marginLeft: 1 }}></PersonIcon>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Routes>
        {PAGES.map((page, index) => (
          <Route key={index} exact path={`/${page}`}></Route>
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default HeaderMUI;
