import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAudioService } from "../Hook/AudioHook";
import ContentGenerationTab from "../components/audio/content_generation";
import SummarizeTab from "../components/audio/summarize";
import TranscriptionTab from "../components/audio/transcription";
import "../components/cstm.css";
import CustomTabPanel, { a11yProps } from "../components/tabs/CustomTabPanel";
import { tokens } from "../theme";
import { backendApiUrl } from "../utils/constants";

const LoadingOverlay = ({ loading, children }) => (
  <div style={{ position: "relative", minHeight: "100vh" }}>
    {loading && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <CircularProgress size={60} />
      </div>
    )}
    {children}
  </div>
);

const UploadAudio = () => {
  const { accessToken, userName } = useSelector((state) => state.auth);

  const [tab, setTab] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [allFilelist, setAllFileList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    setTranscribeBtn,
    audioFile,
    setAudioFile,
    processing,
    upload_file,
    handleAudioSelect,
    selectedLanguage,
    setSelectedLanguage,
    isFileSelect,
    handleGetUsagesByUsername,
    usagesData,
  } = useAudioService();

  useEffect(() => {
    handleListOfFile();
  }, []);

  // const [selectedOption, setSelectedOption] = useState(
  //   "Transchribe with user Recognition"
  // );
  const { t, i18n } = useTranslation();
  // console.log("audioFile: ", audioFile);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleListOfFile = async () => {
    try {
      const formData = new FormData();
      formData.append("token", accessToken);
      const url = `${backendApiUrl}/audio/list_of_files`;
      const res = await axios.post(url, formData);

      if (res?.data?.status === "success") {
        const { message } = res.data;
        setAllFileList(message);
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      const errorMessage = error.message || error.toString();
      notification.error({
        message: errorMessage,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
          width: "290px",
        },
      });
    }
  };

  useEffect(() => {
    if (accessToken && userName) {
      handleGetUsagesByUsername();
    }
  }, [accessToken]);

  const handleFileupload = async () => {
    setUploadSuccess(false);

    try {
      await upload_file();
      await handleListOfFile();
    } catch (error) {
      console.error("Error during API calls", error);
    }
  };

  const columns = [{ field: "fileName", headerName: "File Name", width: 500 }];

  const rows = allFilelist?.map((file, index) => ({
    id: index,
    fileName: file,
  }));

  const handleSelectionChange = (newSelection) => {
    setTranscribeBtn(true);
    let selectRow = [newSelection[newSelection.length - 1]];
    const selectedRow = rows.find((row) => row.id === selectRow[0]);
    setSelectionModel(selectRow);
    setAudioFile(selectedRow?.fileName);
  };

  return (
    <LoadingOverlay loading={processing}>
      <Grid container sx={{ paddingTop: "30px" }}>
        <Grid item xs={6} sx={{ paddingRight: 2 }}>
          <Box
            className="audio"
            sx={{
              width: "100%",
              height: "85vh",
              overflowY: "auto",
              paddingBottom: 8,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: 3,
              padding: 3,
            }}
          >
            <Card
              sx={{
                width: "100%",
                marginBottom: "10px",
                position: "relative",
                textAlign: "center",
                border: 0,
                boxShadow: "none",
              }}
            >
              <CardContent
                sx={{ border: "1px dashed #d0d0d0", borderRadius: 3 }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: colors.cstmBlue[500],
                  }}
                >
                  {" "}
                  {t("upload_audio")}
                </Typography>
                <br />
                <input
                  type="file"
                  disabled={uploadSuccess}
                  onChange={(e) => {
                    setSelectionModel([]);
                    handleAudioSelect(e);
                  }}
                  multiple
                  style={{ display: "none" }}
                  id="audio_input"
                />
                <label htmlFor="audio_input">
                  <Button
                    disabled={uploadSuccess}
                    component="span"
                    sx={{
                      cursor: uploadSuccess ? "not-allowed" : "pointer",
                      backgroundColor: colors.cstmPurple[500],
                      borderRadius: "100px",
                    }}
                    variant="contained"
                  >
                    {t("choose_audio")}
                  </Button>
                </label>
                <br /> <br />
                <Typography variant="h5" sx={{ color: "#d0d0d0" }}>
                  {processing ? (
                    <CircularProgress />
                  ) : audioFile ? (
                    `Geselecteerd bestand: ${audioFile?.name || audioFile}`
                  ) : (
                    t("file_text")
                  )}
                </Typography>
              </CardContent>
            </Card>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 230,
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {t("select_langtext")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                label="Select Language"
              >
                <MenuItem value={"Dutch"} onClick={() => changeLanguage("nl")}>
                  Dutch
                </MenuItem>
                <MenuItem
                  value={"English"}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              disabled={!isFileSelect}
              onClick={handleFileupload}
              variant="contained"
              sx={{
                marginRight: "10px",
                width: "170px",
                height: "50px",
                fontSize: "0.95rem",
                fontWeight: "bold",
                borderRadius: "100px",
                margin: "5px auto",
                display: "flex",
                backgroundColor: colors.cstmBlue[500],
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              style={{ marginRight: "10px" }}
            >
              {processing ? <CircularProgress size={24} /> : t("Upload File")}
            </Button>

            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: "#fff !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.cstmPurple[500],
                  borderBottom: "none",
                  fontSize: "12px",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.cstmPurple[500],
                },
                "& .MuiCheckbox-root": {
                  color: `"#fff",!important`,
                },
                "& .MuiDataGrid-row": {
                  fontSize: "14px",
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionChange}
                getRowId={(row) => row.id}
              />
            </Box>

            {/* <button onClick={() => changeLanguage('en')}>English</button>
            <button onClick={() => changeLanguage('nl')}>Nederlands</button> */}
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ paddingLeft: 2 }}>
          <Box
            sx={{
              width: "100%",
              height: "85vh",
              overflowY: "auto",
              paddingBottom: 8,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: 3,
              padding: 3,
            }}
          >
            <Box sx={{}}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Audio Tabs"
                // indicatorColor="primary"
                className="cstmTab"
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                  border: "1px solid #402E7A",
                }}
              >
                <Tab label={t("transcription_tab_label")} {...a11yProps(0)} />
                <Tab label={t("summry_tab_label")} {...a11yProps(1)} />
                <Tab label={t("contentGen_tab_label")} {...a11yProps(2)} />
              </Tabs>
            </Box>
            <div>
              <h2>
                Total count of all usage: {usagesData?.totalUsage ?? `0 minutes`} 
              </h2>
              <CustomTabPanel value={tab} index={0}>
                <TranscriptionTab
                  handleGetUsagesByUsername={handleGetUsagesByUsername}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <SummarizeTab />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={2}>
                <ContentGenerationTab />
              </CustomTabPanel>
            </div>
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

export default UploadAudio;
